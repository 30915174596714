import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { GridSortItem } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import {
  Alert, Box, Button, IconButton, Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { EntityFile, EntityFileStatus } from './EntityFile';
import { EntityFileStatusAction, getNewEntityFileStatus } from './EntityFileStatusEngine';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityFileApi } from './NiobiAdminEntityFileApi';
import EntityFileTable from './EntityFileTable';
import { ScreenErrorAlert } from '../../components/ScreenErrorAlert';
import { EntityFileUploadDialog } from './upload/EntityFileUploadDialog';
import OnboardingInfoDialog from './OnboardingInfoDialog';
import { NiobiAdminEntityApi } from '../entities/NiobiAdminEntityApi';
import NewFileUploadDialog from './NewFileUploadDialog';
// import EntityCountriesTable from '../entities/EntityCountriesTable';
import SetupProfileDialog from './SetupProfileDialog';
// import EntityCountriesTable from '../entities/EntityCountriesTable';

export default function SingleEntityFiles() {
  const location = useLocation();
  // const navigate = useNavigate();
  const {
    entityName, entityId, user, entityType,
  } = location.state || {};

  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  // const entityApi = useMemo(
  //   () => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout),
  //   [auth],
  // );
  const {
    isLoading,
    entities,
    // countries,
    totalNumEntities,
    errorMessage,
    refresh,
    setPaginationFilter,
    setSortFilter,
  } = useEntityFiles();

  const [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = useState(false);
  const [statusUpdateError, setStatusUpdateError] = useState<string>();
  const [fileToUpdate, setFileToUpdate] = useState<EntityFile>();
  const [openOnboardingAction, setOpenOnboardingAction] = React.useState<Boolean>(false);
  const [openUploadFileAction, setOpenUploadFileAction] = React.useState<Boolean>(false);
  const [openSetupProfileAction, setOpenSetupProfileAction] = React.useState<Boolean>(false);
  const [applyFcyAccountError, setApplyFcyAccountError] = useState<string | undefined>(undefined);
  console.log(setApplyFcyAccountError);
  const [applyFcyAccountresponse,
    setApplyFcyAccountresponse] = useState<string | undefined>(undefined);

  const handleTableQueryChange = (
    paginationModel?: GridPaginationModel,
    sortModel?: GridSortItem[],
  ) => {
    setPaginationFilter(paginationModel);
    setSortFilter(sortModel);
  };

  // const handleViewSettlement = (selectedEntity: string) => {
  //   console.log(selectedEntity);
  //   navigate('/settlements', { state: { selectedEntity } });
  // };

  // const handleViewFees = (selectedEntity: string) => {
  //   console.log(selectedEntity);
  //   navigate('/custom-fees', { state: { selectedEntity } });
  // };

  // const applyForeignCurrencyAccount = (entity: string) => {
  //   console.log('Apply Foreign Currency Account', entity);
  //   setIsLoadingStatusUpdate(true);
  //   entityApi.applyFcyAccount(entity)
  //     .then((response) => setApplyFcyAccountresponse(response.message))
  //     .catch((err) => setApplyFcyAccountError(err.message));
  // };

  const handleViewOnboarding = () => {
    setOpenOnboardingAction(true);
  };

  const handleUploadNewFile = () => {
    setOpenUploadFileAction(true);
  };

  const handleSetupProfile = () => {
    setOpenSetupProfileAction(true);
  };

  const handleStatusChange = (file: EntityFile, action: EntityFileStatusAction) => {
    const newStatus = getNewEntityFileStatus(action);
    setIsLoadingStatusUpdate(true);
    api.update(file.id, { status: newStatus })
      .then(() => refresh())
      .then(() => setStatusUpdateError(undefined))
      .catch((err) => setStatusUpdateError(err.message))
      .finally(() => setIsLoadingStatusUpdate(false));
  };

  const handleCancelOnboarding = () => {
    setOpenOnboardingAction(false);
  };

  const handleCancelUploadFile = () => {
    setOpenUploadFileAction(false);
    refresh();
  };

  const handleCancelSetupProfile = () => {
    setOpenSetupProfileAction(false);
  };

  const handleDownload = async () => {
    const zip = new JSZip();

    // Add files to the ZIP
    // const files = [
    //   { name: 'file1.txt', url: 'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Screenshot%20from%202024-09-21%2014-09-05-2024-09-24.png' },
    //   { name: 'file2.txt', url: 'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Screenshot%20from%202024-09-21%2015-41-37-2024-09-24.png' },
    // ];

    const docs = entities?.map((doc) => {
      const parts = doc.url?.split('/') || [];
      const filenameWithExtension = parts.pop();

      return { name: filenameWithExtension, url: doc.url };
    });

    const promises = docs.map(async (file) => {
      if (file && file.name && file.url) {
        const response = await fetch(file?.url, { mode: 'cors', cache: 'no-cache' });
        const blob = await response.blob();
        zip.file(file.name, blob);
      }
    });

    // Wait for all files to be added
    await Promise.all(promises);

    // Generate the ZIP file and trigger download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${entityName}_documents.zip`);
    });
  };

  return (

    <div>
      <h3>{` Entity Name: ${entityName}`}</h3>
      <p>{`Entity Id: ${entityId}`}</p>
      <p>{`Entity Owner: ${user.name}`}</p>
      <p>{`Entity email: ${user.email}`}</p>
      <p>{`Entity profile: ${user.profile}`}</p>
      {/* <p>{`Entity : ${entityType.id}`}</p> */}

      <ScreenErrorAlert className="alert" isLoading={isLoading} errorMessage={errorMessage} onRetry={refresh} />
      <ScreenErrorAlert className="alert" isLoading={isLoadingStatusUpdate} errorMessage={statusUpdateError} onClose={() => setStatusUpdateError(undefined)} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <Button variant="contained"
        onClick={() => handleViewSettlement(entityId)}>View Settlements</Button> */}
        {/* <Button variant="contained"
        onClick={() => handleViewFees(entityId)}>View Custom Fee structure</Button> */}
        {/* <Button variant="contained"
         onClick={() => applyForeignCurrencyAccount(entityId)}>Apply USD Account</Button> */}
        <Button variant="contained" onClick={() => handleViewOnboarding()}>View Choice Onboarding Info</Button>
        <Button variant="contained" onClick={() => handleUploadNewFile()}>Upload New Documents</Button>
        <Button variant="contained" onClick={() => handleSetupProfile()}>Setup Customer Profile</Button>
        <Button variant="contained" onClick={handleDownload}>Download Business Documents</Button>
      </div>
      {
        (applyFcyAccountError || applyFcyAccountresponse)
        && (
        <div style={{ marginTop: '20px' }}>
          { applyFcyAccountresponse && (
            <Alert
              action={(
                <Stack direction="row">
                  <IconButton
                    color="error"
                    onClick={() => setApplyFcyAccountresponse(undefined)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Stack>
        )}
              severity="info"
            >
              {applyFcyAccountresponse}
            </Alert>
          )}
          { applyFcyAccountError && <Alert severity="error">{applyFcyAccountError}</Alert>}
        </div>
        )
      }

      <h4>Entity Documents</h4>
      {
        entities.length > 0
        && (
        <Box className="table-container" style={{ height: '340px', display: 'flex' }}>
          <EntityFileTable
            isLoading={isLoading}
            files={entities}
            totalNumItems={totalNumEntities}
            onQueryChange={handleTableQueryChange}
            onEntityFileStatusAction={handleStatusChange}
            onClickUpdate={setFileToUpdate}
          />
        </Box>
        )
      }

      <h4>Selected Countries</h4>
      {/* {
        entities.length > 0
        && (
        <EntityCountriesTable countries={countries} />
        )
      } */}
      {fileToUpdate && (
        <EntityFileUploadDialog
          open={!!fileToUpdate}
          file={fileToUpdate}
          onClose={() => setFileToUpdate(undefined)}
          onUpload={() => {
            refresh();
            setFileToUpdate(undefined);
          }}
        />
      )}

      <OnboardingInfoDialog
        onClose={handleCancelOnboarding}
        entityId={entityId}
        open={!!openOnboardingAction}
      />

      <NewFileUploadDialog
        userId={user?.id}
        documentType={`${entityType.id}`}
        onClose={handleCancelUploadFile}
        open={!!openUploadFileAction}
      />

      <SetupProfileDialog
        userId={user?.id}
        onClose={handleCancelSetupProfile}
        open={!!openSetupProfileAction}
      />
    </div>
  );
}

export type OnEntityFileStatusAction = (file: EntityFile, action: EntityFileStatusAction) => any;

function useEntityFiles() {
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  const eApi = useMemo(() => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout), [auth]);

  const location = useLocation();
  const { entityName: paramEntityName } = location.state || {};

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<EntityFile[]>([]);
  const [totalNumFiles, setTotalNumFiles] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [countries, setCountries] = useState<any>([]);

  const [paginationFilter, setPaginationFilter] = useState<GridPaginationModel>();
  const [sortFilter, setSortFilter] = useState<GridSortItem[]>();
  const [fileStatusFilter, setFileStatusFilter] = useState<EntityFileStatus>();
  const [entityNameFilter, setEntityNameFilter] = useState<string>(paramEntityName);
  const [userFirstNameFilter, setUserFirstNameFilter] = useState<string>();
  const [userLastNameFilter, setUserLastNameFilter] = useState<string>();

  const fetchEntities = useCallback(() => {
    const pagination = paginationFilter;
    const sort = sortFilter;
    const status = fileStatusFilter;
    const entityName = entityNameFilter;
    const userFirstName = userFirstNameFilter;
    const userLastName = userLastNameFilter;

    const offset = pagination ? pagination.page * pagination.pageSize : undefined;
    const limit = pagination?.pageSize;
    const sortField = sort?.[0]?.field || 'id';
    const sortAsc = sort?.[0]?.sort ? sort[0].sort !== 'desc' : false;

    setIsLoading(true);
    api.findAll({
      offset,
      limit,
      sortField,
      sortAsc,
      status,
      documentEntityNameLike: entityName,
      documentUserFirstNameLike: userFirstName,
      documentUserLastNameLike: userLastName,
    })
      .then((response) => {
        setFiles(response.items);
        setTotalNumFiles(response.paginationInfo.totalNumItems);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [
    api,
    paginationFilter,
    sortFilter,
    fileStatusFilter,
    entityNameFilter,
    userFirstNameFilter,
    userLastNameFilter,
  ]);

  const fetchCountries = useCallback(() => {
    eApi.getUserCountries()
      .then((response) => {
        console.log('response', response?.data);
        setCountries(response?.data);
      })
      .catch((err) => console.log(err));
  }, [eApi]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchEntities();
    fetchCountries();
  }, [fetchEntities, fetchCountries, isInitialRender]);

  return {
    isLoading,
    entities: files,
    countries,
    totalNumEntities: totalNumFiles,
    errorMessage,
    refresh: fetchEntities,
    setPaginationFilter,
    setSortFilter,
    setFileStatusFilter,
    setEntityNameFilter,
    setUserFirstNameFilter,
    setUserLastNameFilter,
  };
}
