import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  DataGrid, GridColDef, GridSortItem, GridSortModel,
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import LinearProgress from '@mui/material/LinearProgress';

export default function NiobiTable<T>(props: {
  isLoading: boolean,
  columns: GridColDef[],
  handleOnRowClick?: (params: any) => void,
  rows: T[],
  totalNumItems: number,
  onQueryChange: (paginationModel?: GridPaginationModel, sortModel?: GridSortItem[]) => any,
}) {
  const {
    isLoading,
    columns,
    rows,
    totalNumItems,
    handleOnRowClick,
    onQueryChange,
  } = props;
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const handleRowClick = (params:any) => {
    console.log('handleRowClick', handleOnRowClick);
    if (handleOnRowClick) {
      handleOnRowClick(params);
    }
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    onQueryChange(paginationModel, sortModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialRender,
    paginationModel,
    sortModel,
  ]);

  return (
    <Box sx={(theme) => ({
      backgroundColor: theme.palette.background.paper,
      flex: 1,
    })}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        rowCount={totalNumItems}
        density="compact"
        slots={{ loadingOverlay: LinearProgress }}
        // pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onRowClick={handleRowClick}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10, 20, 50]}
        // sorting
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
      />
    </Box>
  );
}
