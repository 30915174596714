import {
  Autocomplete, Box, debounce, Stack, Toolbar,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortItem } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import EntityFileTable from './EntityFileTable';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityFileApi } from './NiobiAdminEntityFileApi';
import {
  EntityFile, EntityFileStatus, EntityFileStatuses, formatEntityFileStatus,
} from './EntityFile';
import { EntityFileStatusAction, getNewEntityFileStatus } from './EntityFileStatusEngine';
import { ScreenErrorAlert } from '../../components/ScreenErrorAlert';
import { INPUT_DEBOUNCE_MS } from '../../Config';
import { EntityFileUploadDialog } from './upload/EntityFileUploadDialog';

export default function EntityFilesScreen() {
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  const {
    isLoading,
    entities,
    totalNumEntities,
    errorMessage,
    refresh,
    setPaginationFilter,
    setSortFilter,
    setFileStatusFilter,
    setEntityNameFilter,
    setUserFirstNameFilter,
    setUserLastNameFilter,
  } = useEntityFiles();
  const [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = useState(false);
  const [statusUpdateError, setStatusUpdateError] = useState<string>();
  const [fileToUpdate, setFileToUpdate] = useState<EntityFile>();

  const handleTableQueryChange = (
    paginationModel?: GridPaginationModel,
    sortModel?: GridSortItem[],
  ) => {
    setPaginationFilter(paginationModel);
    setSortFilter(sortModel);
  };

  const handleStatusFilterChange = (status: EntityFileStatus | null) => {
    setFileStatusFilter(status || undefined);
  };
  const handleEntityNameFilterChange = (value?: string) => {
    setEntityNameFilter(value || undefined);
  };
  const handleUserFirstNameFilterChange = (value?: string) => {
    setUserFirstNameFilter(value || undefined);
  };
  const handleUserLastNameFilterChange = (value?: string) => {
    setUserLastNameFilter(value || undefined);
  };

  const handleStatusChange = (file: EntityFile, action: EntityFileStatusAction) => {
    const newStatus = getNewEntityFileStatus(action);
    console.log('handleStatusChange***', file, action, newStatus);
    setIsLoadingStatusUpdate(true);
    api.update(file.id, { status: newStatus })
      .then(() => refresh())
      .then(() => setStatusUpdateError(undefined))
      .catch((err) => setStatusUpdateError(err.message))
      .finally(() => setIsLoadingStatusUpdate(false));
  };

  return (
    <Box className="entity-files-screen">
      <Stack direction="row" alignItems="center">
        <h3>Documents</h3>
        <Box flexGrow={1} />
      </Stack>
      <ScreenErrorAlert className="alert" isLoading={isLoading} errorMessage={errorMessage} onRetry={refresh} />
      <ScreenErrorAlert className="alert" isLoading={isLoadingStatusUpdate} errorMessage={statusUpdateError} onClose={() => setStatusUpdateError(undefined)} />
      <Toolbar disableGutters className="toolbar">
        <Stack direction="row" spacing={1}>
          <Autocomplete
            className="toolbar-input"
            disablePortal
            options={EntityFileStatuses}
            getOptionLabel={(option) => formatEntityFileStatus(option)}
              /* eslint-disable-next-line react/jsx-props-no-spreading */
            renderInput={(params) => <TextField {...params} label="Status" />}
            onChange={(event, value) => handleStatusFilterChange(value)}
          />
          <TextField
            className="toolbar-input"
            label="Entity Name"
            variant="outlined"
            onChange={debounce(
              (event) => handleEntityNameFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
          <TextField
            className="toolbar-input"
            label="User First Name"
            variant="outlined"
            onChange={debounce(
              (event) => handleUserFirstNameFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
          <TextField
            className="toolbar-input"
            label="User Last Name"
            variant="outlined"
            onChange={debounce(
              (event) => handleUserLastNameFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
        </Stack>
      </Toolbar>
      <EntityFileTable
        isLoading={isLoading}
        files={entities}
        totalNumItems={totalNumEntities}
        onQueryChange={handleTableQueryChange}
        onEntityFileStatusAction={handleStatusChange}
        onClickUpdate={setFileToUpdate}
      />
      {fileToUpdate && (
        <EntityFileUploadDialog
          open={!!fileToUpdate}
          file={fileToUpdate}
          onClose={() => setFileToUpdate(undefined)}
          onUpload={() => {
            refresh();
            setFileToUpdate(undefined);
          }}
        />
      )}
    </Box>
  );
}

export function useEntityFiles() {
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<EntityFile[]>([]);
  const [totalNumFiles, setTotalNumFiles] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [paginationFilter, setPaginationFilter] = useState<GridPaginationModel>();
  const [sortFilter, setSortFilter] = useState<GridSortItem[]>();
  const [fileStatusFilter, setFileStatusFilter] = useState<EntityFileStatus>();
  const [entityNameFilter, setEntityNameFilter] = useState<string>();
  const [userFirstNameFilter, setUserFirstNameFilter] = useState<string>();
  const [userLastNameFilter, setUserLastNameFilter] = useState<string>();

  const fetchEntities = useCallback(() => {
    const pagination = paginationFilter;
    const sort = sortFilter;
    const status = fileStatusFilter;
    const entityName = entityNameFilter;
    const userFirstName = userFirstNameFilter;
    const userLastName = userLastNameFilter;

    const offset = pagination ? pagination.page * pagination.pageSize : undefined;
    const limit = pagination?.pageSize;
    const sortField = sort?.[0]?.field || 'id';
    const sortAsc = sort?.[0]?.sort ? sort[0].sort !== 'desc' : false;

    setIsLoading(true);
    api.findAll({
      offset,
      limit,
      sortField,
      sortAsc,
      status,
      documentEntityNameLike: entityName,
      documentUserFirstNameLike: userFirstName,
      documentUserLastNameLike: userLastName,
    })
      .then((response) => {
        setFiles(response.items);
        setTotalNumFiles(response.paginationInfo.totalNumItems);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [
    api,
    paginationFilter,
    sortFilter,
    fileStatusFilter,
    entityNameFilter,
    userFirstNameFilter,
    userLastNameFilter,
  ]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchEntities();
  }, [fetchEntities, isInitialRender]);

  return {
    isLoading,
    entities: files,
    totalNumEntities: totalNumFiles,
    errorMessage,
    refresh: fetchEntities,
    setPaginationFilter,
    setSortFilter,
    setFileStatusFilter,
    setEntityNameFilter,
    setUserFirstNameFilter,
    setUserLastNameFilter,
  };
}
