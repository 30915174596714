import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { GridColDef, GridSortItem } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
// import moment from 'moment';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import DoneAll from '@mui/icons-material/DoneAll';
import { Airplay, Api } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import NiobiTable from '../../components/NiobiTable';
import {
  Entity, EntityStatus, EntityType, formatEntityStatus,
} from './Entity';
import { EntityStatusAction, getEntityStatusActions } from './EntityStatusEngine';
import { User } from './User';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityFileApi } from '../entityfiles/NiobiAdminEntityFileApi';
import { NiobiAdminEntityApi } from './NiobiAdminEntityApi';

export default function EntityTable(props: {
  isLoading: boolean,
  entities: Entity[],
  totalNumItems: number,
  onQueryChange: (paginationModel?: GridPaginationModel, sortModel?: GridSortItem[]) => any,
  onEntityStatusAction: OnEntityStatusAction,
  onEntityUploadDocuments:any
  onEntitySubmit:any
}) {
  const {
    isLoading,
    onQueryChange,
    totalNumItems,
    entities,
    onEntityStatusAction,
    onEntitySubmit,
    onEntityUploadDocuments,
  } = props;
  const navigator = useNavigate();

  const handleRowClick = (params:any) => {
    console.log('handleRowClick I am getting here', params.row);
    navigator(`/entities/${params.row.name}`, {
      state: {
        entityName: params.row.name,
        entityId: params.row.id,
        entityType: params.row.entityType,
        user:
        {
          name: params.row.user.name,
          email: params.row.user.email,
          id: params.row.user.id,
        },
      },
    });
  };
  return (
    <NiobiTable
      isLoading={isLoading}
      columns={columns(onEntityStatusAction, onEntityUploadDocuments, onEntitySubmit)}
      rows={entities}
      handleOnRowClick={handleRowClick}
      totalNumItems={totalNumItems}
      onQueryChange={onQueryChange}
    />
  );
}

const columns = (
  onEntityStatusAction: OnEntityStatusAction,
  onEntityUploadDocuments:any,
  onEntitySubmit:any,
): GridColDef[] => [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Entity Name', width: 200 },
  {
    field: 'entityType.name',
    headerName: 'Entity Type',
    width: 130,
    valueGetter: (params) => (params.row.entityType as EntityType)?.name,
  },
  {
    field: 'user.firstName',
    headerName: 'User',
    width: 180,
    valueGetter: (params) => {
      const user = (params.row.user as User);
      return `${user?.firstName || ''} ${user?.lastName || ''}`;
    },
  },
  {
    field: 'user.email',
    headerName: 'Email',
    flex: 1,
    valueGetter: (params) => (params.row.user as User)?.email,
  },
  {
    field: 'isApiClinet',
    headerName: 'Client Type',
    width: 100,
    renderCell: (params) => <ClientTypeChip type={params.row.isApiClient} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 160,
    align: 'center',
    renderCell: (params) => <EntityStatusChip status={(params.row as Entity).status} />,
  },
  {
    field: 'isBankCustomer',
    headerName: 'Bank Customer',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <EntityTableRowActions
        entity={params.row as Entity}
        onStatusAction={onEntityStatusAction}
        onEntityUploadDocuments={onEntityUploadDocuments}
        onEntitySubmit={onEntitySubmit}
      />
    ),
    align: 'right',
  },
];

function EntityStatusChip(props: { status: EntityStatus }) {
  const { status } = props;
  const getColor = () => {
    switch (status) {
      case EntityStatus.NEW: return 'info';
      case EntityStatus.VERIFIED: return 'success';
      case EntityStatus.UNVERIFIED: return 'warning';
      case EntityStatus.VERIFYLEVELONE: return 'success';
      case EntityStatus.SUBMITED_TO_CHOICE: return 'info';
      case EntityStatus.SUBMITED_ENTITY_ONBOARDING_TO_CHOICE: return 'info';
      // case EntityStatus.SUSPENDED: return 'error';
      default: {
        console.warn(`EntityStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  const getIcon = () => {
    switch (status) {
      case EntityStatus.NEW: return <PersonAddIcon />;
      case EntityStatus.VERIFIED: return <DoneAll />;
      case EntityStatus.UNVERIFIED: return <CloseIcon />;
      case EntityStatus.VERIFYLEVELONE: return <CheckIcon />;
      // case EntityStatus.SUSPENDED: return <BlockIcon />;
      default: {
        console.warn(`EntityStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  return (
    <Tooltip title={formatEntityStatus(status)}>
      <Chip
        sx={{ width: '100%' }}
        label={formatEntityStatus(status)}
        variant="outlined"
        icon={getIcon()}
        color={getColor()}
      />
    </Tooltip>
  );
}

function ClientTypeChip(props: { type: boolean }) {
  const { type } = props;
  const getColor = () => (type ? 'success' : 'info');
  const getIcon = () => (type ? <Api /> : <Airplay />);
  return (
    <Tooltip title={type ? 'API' : 'APP'}>
      <Chip
        sx={{ width: '100%' }}
        label={type ? 'API' : 'APP'}
        variant="outlined"
        icon={getIcon()}
        color={getColor()}
      />
    </Tooltip>
  );
}

export type OnEntityStatusAction = (entity: Entity, action: EntityStatusAction) => any;

function EntityTableRowActions(props: {
  entity: Entity,
  onStatusAction: OnEntityStatusAction,
  onEntityUploadDocuments:any
  onEntitySubmit:any
}) {
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  const entityApi = useMemo(
    () => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  const {
    entity, onStatusAction, onEntitySubmit, onEntityUploadDocuments,

  } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoadingActions, setIsLoadingActions] = React.useState<boolean>(true);
  const [entityStatusActions, setEntityStatusActions] = React.useState<EntityStatusAction[]>([]);
  const [menuOpened, setMenuOpened] = React.useState<boolean>(false);
  const [actionToConfirm, setActionToConfirm] = React.useState<EntityStatusAction>();

  useEffect(() => {
    if (!menuOpened) return;
    setIsLoadingActions(true);
    setEntityStatusActions([]);
    getEntityStatusActions(api, entityApi, entity.id.toString(), entity.status)
      .then(setEntityStatusActions)
      .finally(() => setIsLoadingActions(false));
  }, [api, entity, entityApi, menuOpened]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleSelectAction = (action: EntityStatusAction) => {
    setActionToConfirm(action);
  };

  const handleCancelAction = () => {
    handleMenuClose();
    setActionToConfirm(undefined);
  };

  const handleConfirmAction = () => {
    if (actionToConfirm !== EntityStatusAction.SUBMITDOCUMENTS
       && actionToConfirm !== EntityStatusAction.SUBMITENTITY) {
      onStatusAction(entity, actionToConfirm!);
    } else if (actionToConfirm === EntityStatusAction.SUBMITDOCUMENTS) {
      onEntityUploadDocuments(entity, actionToConfirm);
    } else if (actionToConfirm === EntityStatusAction.SUBMITENTITY) {
      onEntitySubmit(entity, '1', actionToConfirm);
    }
    handleMenuClose();
    handleCancelAction();
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleMenuClose}
      >
        {isLoadingActions && <MenuItem disabled><CircularProgress /></MenuItem>}
        {entityStatusActions.map((action) => (
          <MenuItem key={action} onClick={() => handleSelectAction(action)}>{action}</MenuItem>
        ))}
      </Menu>
      <EntityStatusChangeAlertDialog
        open={!!actionToConfirm}
        onClose={handleCancelAction}
        onConfirm={handleConfirmAction}
      />
    </>
  );
}
function EntityStatusChangeAlertDialog(props: {
  open: boolean,
  onClose: VoidFunction,
  onConfirm: VoidFunction,
}) {
  const { open, onClose, onConfirm } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update Entity Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to update the entity status?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
